@tailwind base;
@tailwind components;
@tailwind utilities;

/* -------------------
TYPOGRAPHY
---------------------- */

:root {
    --sans-serif-type: "Inter Tight", sans-serif;
    --serif-type: "Newsreader", serif;
    --caps-letter-spacing: 0.225ch;
    --sans-head-letter-spacing: 0.02ch;
}

html {
    font-family: var(--sans-serif-type);
    height: 100%;
    margin: 0;
    background: #eeeeee;
}

body {
    height: 100%;
    margin: 0;
    background: #eeeeee;
    color: black;
}

#root {
    height: 100vh;
}

#page-container{
    position: relative;
    min-height: 100vh;
}

#footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2.5rem;
}

h1,
h2 {
    font-family: var(--serif-type);
}

h1 {
    letter-spacing: -0.015em;
    font-weight: 440;
}

h2 {
    font-weight: 500;
}

h3,
h4,
h5,
h6 {
    font-family: var(--sans-serif-type);
    font-weight: 400;
}

h3 {
    letter-spacing: var(--sans-head-letter-spacing);
}

h4 {
    font-weight: 500;
    letter-spacing: var(--sans-head-letter-spacing);
}

h5 {
    font-weight: 600;
    letter-spacing: var(--sans-head-letter-spacing);
}

h6 {
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: var(--caps-letter-spacing);
}

p {
    font-family: var(--serif-type);
    font-weight: 430;
    border: 1px darkred;
}

dt {
    font-weight: 600;
    font-family: var(--serif-type);
    text-transform: uppercase;
    letter-spacing: var(--caps-letter-spacing);
}

dd {
    letter-spacing: var(--sans-head-letter-spacing);
}

figcaption {
    font-family: var(--serif-type);
}

sub,
sup {
    font-family: var(--sans-serif-type);
}

.App{
    height:100%;
    min-height: inherit;
    background-repeat: repeat;
}

.appBackground{
    background: #eeeeee;
    background-repeat: repeat-y;

}

.wrapper {
    width: 100%;
    height: 100px;
    background: black;
    margin-top: 10px;
}

.scrollable-div {
    width: 400px;
    overflow-y: scroll;
    text-align: justify;
}

.stickyTop{
    z-index: 2;
    position: sticky;
    top: 10px;
}

.fullScreenLoading{
    width: 1600px;
    height: 100vh;
    background: white;
    color: darkgray;
}

.componentLoading{
    position: absolute;
    width: 780px;
    height: 690px;
    background: white;
    color: darkgray;
}

.campaignSaving{
    position: relative;
    margin-top: -600px;
    width: 100vw;
    height: 700px;
    background: white;
    color: darkgray;
}

.dataMapperLoading{
    width: 800px;
    height: 2175px;
    background: white;
    color: darkgray;
}

.componentTwoHighLoading{
    position: absolute;
    width: 500px;
    height: 500px;
    background: white;
    color: darkgray;
}

.hierarchyNavigationTile{
    width: 500px;
    height: 100px;
    margin: 10px 10px 10px 0px;
    background: white;
    border-radius: 10px;
    border-color: white;
    box-shadow: 5px 3px 10px lightgray;
}

.hierarchyNavigationTileCreateCampaign{
    width: 390px;
    height: 100px;
    margin: 10px 10px 10px 0px;
    background: white;
    border-radius: 10px;
    border-color: white;
    box-shadow: 5px 3px 10px lightgray;
    background: #94e936;
}

.hierarchyNavigationTileCreateEvent{
    width: 390px;
    height: 100px;
    margin: 10px 10px 10px 0px;
    background: white;
    border-radius: 10px;
    border-color: white;
    box-shadow: 5px 3px 10px lightgray;
    background: #ffa204;
}

.hierarchyNavigationTileFull{
    width: 1600px;
    height: 100px;
    margin: 10px 00px 10px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.hierarchyNavigationTileDouble{
    width: 790px;
    height: 100px;
    margin: 10px 10px 10px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.hierarchy_nav{
    margin-left: 100px;
}

.hierarchy_nav_full{
    margin-left: 495px;
}

.hierarchy_label{
    position: absolute;
    font-family: sans-serif;
    font-size: 10px;
    color: black;
}

.navigationCreateEvent{
    cursor: pointer;
}

.navigationCreateCampaign{
    cursor: pointer;
}

.navigationCreateCampaignDiv{
    width: 375px;
    height: 100px;
    border-radius: 10px;
    background: #94e936;/*#346793;*/
    box-shadow: 5px 3px 10px lightgray;
    margin-left: 10px;
}


.navigationCancelDiv{
    width: 375px;
    height: 100px;
    border-radius: 10px;
    background: #8f0000;
    box-shadow: 5px 3px 10px lightgray;
}

.navigationCancel{
    cursor: pointer;
}

.navigationCancelTitle{
    font-family: sans-serif;
    font-size: 32px;
    font-weight: bold;
    color: white;
}

.mainPageDiv{
    width: 1600px;
    position: absolute;
    margin-left: max(5px, calc((100vw - 1600px)/2));
    background: white;
    color: black;
}

.navigationStops{
}

.createItemTitle{
    font-family: sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: #17326f;
}

.dashboardTileTitle{
    font-family: sans-serif;
    font-size: 18px;
    font-weight: bold;
}

.dashboardTileMainData{
    font-family: sans-serif;
    font-size: 48px;
    font-weight: bold;
}

.dashboardTileSubtext{
    font-family: sans-serif;
    font-size: 14px;
    margin-right: 10px;
}

.dashboardTile{
    width: 390px;
    height: 370px;
    margin: 0px 10px 10px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.dashboardTileRightCol{
    width: 400px;
    height: 370px;
    margin: 0px 0px 10px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.dashboardHalfHeightTile{
    width: 390px;
    height: 180px;
    margin: 0px 10px 10px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 3px 10px lightgray;
    min-width: 390px;
    overflow-y: hidden;
}

.dashboardDoubleHeightTile{
    width: 390px;
    height: 750px;
    margin: 0px 10px 10px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.dashboardDoubleWidthTile{
    width: 790px;
    height: 370px;
    margin: 0px 10px 10px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.dashboardDoubleWidthDoubleHeightTile{
    width: 790px;
    height: 750px;
    margin: 0px 10px 10px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.dashboardTripleWidthDoubleHeightTile{
    width: 1200px;
    height: 750px;
    margin: 0px 10px 10px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.dashboardFullWidthTile{
    width: 1600px;
    height: 370px;
    margin: 0px 10px 10px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.dashboardFullWidthHalfHeightTile{
    width: 1600px;
    height: 180px;
    margin: 0px 10px 10px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.dashboardFullWidthDoubleHeightTile{
    width: 1600px;
    height: 750px;
    margin: 0px 10px 10px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.dashboardFullWidthTripleHeightTile{
    width: 1600px;
    height: 1030px;
    margin: 0px 10px 10px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.dashboardCampaignListFilters_Modal{
    overflow-y: scroll;
    max-height: 50vw;
    width: 437px;
    height: 1000px;
    margin: 10px 0px 20px 0px;
    background: white;
    border-radius: 10px;
    border-color: #346793;
    padding: 10px 10px 10px 10px;
    border-width: 5px;

    vertical-align: top;
}

.dashboardCampaignListFiltersTitle_Modal{
    font-family: sans-serif;
    font-size: 24px;
    font-weight: bold;
}

.dashboardCampaignList_Modal{
    overflow-y: scroll;
    max-height: 50vw;
    width: 1150px;
    height: 1000px;
    margin: 10px 10px 20px 10px;
    background: white;
    border-radius: 10px;
    border-color: #346793;
    padding: 10px 10px 10px 10px;
    border-width: 5px;

    vertical-align: top;
}

.dashboardCampaignListContents_Modal{
    flex-wrap: wrap;
    background: white;
    width: 1540px;
}

.dashboardCampaign_Modal{
    font-family: sans-serif;
    width: 340px;
    height: 120px;
    background-color: white;
    color: black;
    border-width: 4px;
    border-color: #639aca;
    border-radius: 10px;
    border-spacing: 10px;
    cursor: pointer;
    padding: 10px 10px 10px 10px;
    box-shadow: 5px 5px 5px lightgray;
}

.dashboardCampaignHighlight_Modal{
    width: 15px;
    height: 93px;
    background-color: #031236;
    border-radius: 10px 0px 0px 10px;
    margin-right: 5px;
}

.dashboardCampaignTitle_Modal{
    font-weight: bold;
    font-size: 24px;
    font-family: sans-serif;
    max-width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.dashboardCampaignSubTitle_Modal{
    font-size: 10px;
    font-family: sans-serif;
    width: 100px;
}

.dashboardCampaignSubData_Modal{
    font-size: 14px;
    font-weight: bold;
    font-family: sans-serif;
    width: 100px;
}

.dashboardTileHR_Modal{
    border-color: gray;
    margin-bottom: 2px;
}

.dashboardCampaignStates_ModalList{
    flex-wrap: wrap;
    width: 300px;
    height: 700px;
    overflow-y: scroll;
}

.campaignListNavButtons{
    margin-left: 830px;
}

.dashboardCampaignList{
    overflow-y: scroll;
    max-height: 680px;
    margin: 10px 10px 20px 10px;
    vertical-align: top;
    display: inline-block;
}


.dashboardCampaign{
    width: 375px;
    height: 95px;
    background-color: #293f78;
    color: white;
    border-width: 2px;
    border-color: white;
    border-radius: 10px;
    border-spacing: 10px;
    cursor: pointer;
}

.dashboardCampaignHighlight{
    width: 15px;
    height: 93px;
    background-color: #031236;
    border-radius: 10px 0px 0px 10px;
    margin-right: 5px;
}

.dashboardCampaignTitle{
    font-weight: bold;
    font-size: 18px;
    font-family: sans-serif;
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.dashboardCampaignSubTitle{
    font-size: 10px;
    font-family: sans-serif;
    width: 100px;
}

.dashboardCampaignSubData{
    font-size: 14px;
    font-weight: bold;
    font-family: sans-serif;
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.dashboardTileHR{
    border-color: gray;
    margin-bottom: 2px;
}

.dashboardCampaignPendingEvents{
    background: orangered;
    border-width: 2px;
    border-color: white;
    width: 50px;
    height: 35px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    font-family: sans-serif;
    margin-top: 20px;
    margin-left: -20px;
    border-radius: 10px;
    justify-content: center;
    display: flex;
}

.dataMapperFile{
    height: 100px;
    width: 350px;
    border-radius: 20px;
    border-color: gray;
    border-width: 1px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.datamapperFileName{
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dataMapperFileClose{
    cursor: pointer;
}

.eventReportCollapseDetails{
    width: 1600px;
    border-color: black;
    border-radius: 10px;
    background: white;
    box-shadow: 5px 3px 10px lightgray;
    margin: 0px 10px 10px 0px;
}

.accountHeader{
    width: 1600px;
    height: 150px;
    background: black;
    background-image: url("https://afcuploadstorageprod.blob.core.windows.net/assets/1/header.jpeg");
    margin-top: 0px;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.accountHeaderImage{
    margin-top: -90px;
    margin-left: 50px;
    margin-bottom: 10px;
    height: 140px;
    width: 140px;
    z-index: 10;
}

.accountHeaderCompanyName{
    margin-top: -160px;
    margin-left: 210px;
}

.accountHeaderCompanyNameTitle{
    margin-top: 36px;
    font-weight: bold;
    font-size: 48px;
    color: white;
}

.reportHeaderEvenNameTitle{
    margin-top: 36px;
    font-weight: bold;
    font-size: 48px;
    color: white;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 800px;
}

.accountDashboardLoading{
    margin-left: calc(50vw - 48px);
    margin-top: 20px;
}

.dashboardMainStats{
    width: calc(100vw - 50px);
}

.dashboardActivity{
    width: calc(100vw - 50px);
}

.dashboardEventList{
    width: calc(100vw - 50px);
}

.dataTables_filter {
   margin-right: 40px;
}

.dataTable_wrapper{
    font-size: 14px;
}

.eventListComponent{
    width: calc(100vw - 50px);
}

.eventListComponentFull{
    width: 2030px;
}

.eventReportHeader{
    width: 1600px;
    height: 150px;
    background: black;
    background-image: url("https://afcuploadstorageprod.blob.core.windows.net/assets/1/header.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.eventReportHeaderMobile{
    width: 100vw;
    height: 250px;
    background: black;
    background-image: url("https://afcuploadstorageprod.blob.core.windows.net/assets/1/header.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.eventResultsHeaderBody{
    font-family: var(--sans-serif-type);
    color: black;
    font-size: 16px;
    font-weight: bold;
    max-width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.eventResultsHeaderDesc{
    font-family: var(--sans-serif-type);
    color: black;
    font-size: 10px;
}

.eventResultsHeaderTitle{
    font-family: var(--sans-serif-type);
    color: black;
    font-size: 8px;
}

.eventResults_SMSBody{
    font-family: var(--sans-serif-type);
    font-size: 14px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
}

.eventResults_MMSBody{
    font-family: var(--sans-serif-type);
    font-size: 14px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-bottom: 0px;
}

.eventResults_PhoneMMSBody{
    font-family: var(--sans-serif-type);
    font-size: 14px;
    margin-bottom: 0px;
    margin-left: 20px;
    padding-bottom: 0px;
}
.eventResults_MMSPreview{
    border-radius: 10px;
    border-color: white;
    border-width: 2px;
}

.eventResult_PhoneArtBoard{
    display: grid;
    vertical-align: top;
    justify-content: left;
    overflow-y: auto;
    align-content: flex-start;
    padding-top: 30px;
}

.eventResultsHeaderEventTitle{
    font-family: var(--sans-serif-type);
    color: black;
    font-size: 28px;
    font-weight: bold;
}

.eventResultResponseList{
    width: calc(100vw - 50px);
}

.eventTile{
    min-width: 320px;
}

.eventTileMobile{
    min-width: 100vw;
}

.eventReportChart{
    width: calc(50vw - 38px);
}

.eventList{
    width: 780px;
    position: relative;
    background: white;
}

.eventListTable{
    margin: 10px 10px 10px 10px;
}

.eventRespTable{
    margin: 10px 10px 10px 10px;
    width: 2010px;
    max-height: 1000px;
    overflow-x: scroll;
}

.eventListProgress{
    z-index: 9;
}

.eventRefreshButton{
    border: white;
    border-width: 1px;
    border-color: white;
    border-radius: 10px;
}

.container {
    display: flex;
    justify-content: center;
}

.flex-row{
    display: flex;
}

.floating-input > input::placeholder,
.floating-input > textarea::placeholder {
    color: transparent;
}

.floating-input > input:focus ~ label,
.floating-input > input:not(:placeholder-shown) ~ label {
    @apply opacity-75 scale-75 -translate-y-4 translate-x-1;
}

.floating-input > textarea:focus ~ label,
.floating-input > textarea:not(:placeholder-shown) ~ label {
    @apply opacity-75 scale-75 -translate-y-6 translate-x-1;
}

.floating-input > select:focus ~ label,
.floating-input > select:valid ~ label {
    @apply opacity-75 scale-75 -translate-y-4 translate-x-1;
}

.loadingEventReport{
    margin-top: -200px;
    margin-left: -350px;
    width: calc(100vw/4);
}

.loading{
    width: calc(100vw/4);
}

.login-title{
    font-family: Verdana, Arial, sans-serif;
    font-size: 30px;
}

.loginVideo{
    height: 85vh;
    overflow-y: hidden;
    overflow-x: hidden;
}

.loginImage{
    height: 85vh;
    min-width: 2100px;
    overflow-y: hidden;
    overflow-x: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.signinBack{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.signinBody{
    position: fixed;
    width: calc(50vw);
    margin: auto;
    margin-top: min(calc(-1 * 85vh), -700px);
    margin-left: calc((100vw/2) - 192px);
    width: 384px;
    z-index: 9;
}

.signinBody_new2{
    height: 60%;
    width: 384px;
    z-index: 9;
    margin: auto;
}

.signinBody_new{
    position: absolute;
    height: calc(100vh - 300px);
    width: calc(50vw);
    margin: auto;
    width: 384px;
    z-index: 9;
}

.signinFooterTagLineRight{
    margin-top: 50px;
    margin-left: calc(100vw - 650px)
}

.signinFooter_orig{
    position: absolute;
    z-index: 0;
    padding-left: 25px;
    width: 100vw;
    vertical-align: bottom;
}

.signinFooter{
  margin-top: 1rem;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.signinFooterMobile{
    z-index: 0;
    padding-left: calc(100vw - 220px);
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100vw;
    justify-content: center;
    height: "230px";
}

.topBar{
    width: 1600px;
}

.dataMapperMainParent{
    height: 620px;
    border-width: 2px;
    border-style: solid;
    border-color: lightblue;
    background: white;
    border-radius: 10px;
    margin: 10px 15px 10px 15px;
}

.dropFileRectangle{
    display: flex;
    height: 150px;
    border-width: 2px;
    border-style: dashed;
    border-color: lightblue;
    fill: blue;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
}

.dropFileTitle{
    color: lightblue;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 28px;
    cursor: pointer;
}

.draggableItemOptOut{
    border-width: 1px;
    border-radius: 10px;
    border-color: black;
    font-size: 12px;
    font-family: sans-serif;
    width: 250px;
    height: 30px;
    padding-top: 4px;
    text-align: center;
    margin-right: 30px;
    background-color: #e6effc;
    cursor: pointer;
}

.draggableItemMessage{
    border-width: 1px;
    border-radius: 10px;
    border-color: black;
    font-size: 12px;
    font-family: sans-serif;
    width: 250px;
    height: 30px;
    padding-top: 4px;
    text-align: center;
    margin-right: 30px;
    background-color: #ede6fc;
    cursor: pointer;
}

.draggableItemMember{
    border-width: 1px;
    border-radius: 10px;
    border-color: black;
    font-size: 12px;
    font-family: sans-serif;
    width: 250px;
    height: 30px;
    padding-top: 4px;
    text-align: center;
    margin-right: 30px;
    background-color: #fcf3e6;
    cursor: pointer;
}

.eventCreateDetailsBehind{
    position: relative;
    background: lightgrey;
    height: 1290px;
    width: 460px;
    border-radius: 20px;
    margin-top: 0px;
    padding: 10px 10px 10px 20px;
}

.vl {
  border-left: 6px solid black;
  height: 100%;
  border-radius: 99px;
  max-width: 6px;
}

.vl_button{
    position: absolute;
}

.eventCreateFunctionTile{
    background: lightgrey;
    height: 500px;
    width: 454px;
    border-radius: 20px;
    padding: 10px 10px 10px 20px;
    margin-right: 10px;
    background: royalblue;
}

.eventCreateFunctionTileTitle{
    font-family: var(--sans-serif-type);
    color: black;
    font-weight: bold;
    font-size: 18px;
}

.eventCreateFunctionTileSubTitle{
    font-family: var(--sans-serif-type);
    color: black;
    font-size: 14px;
}

.eventCreateFunctionTileCount{
    font-family: var(--sans-serif-type);
    color: white;
    font-weight: bold;
    font-size: 96px;
    max-width: 400px;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.eventCreateFunctionTileSubCount{
    font-family: var(--sans-serif-type);
    color: lightgray;
    font-weight: bold;
    font-size: 48px;
}

.eventCreateFunctionTileInformation{
    font-family: var(--sans-serif-type);
    color: black;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    height: 20px;
    text-decoration: underline;
    color: blue;
}

.eventCreateDetailForm{

}

.eventCreateHeaderInformation{
    border: 2px solid black;
    padding: 20px 20px 20px 20px;
    margin: 0px 20px 10px 0px;
    border-radius: 20px;
}

.eventCreateTiming{
    margin: 0px 20px 10px 0px;
}

.eventEditCalloutTitle{
    font-family: var(--sans-serif-type);
    color: black;
    font-weight: bold;
    font-size: 28px;
}

.eventEditCalloutSectionTitle{
    font-family: var(--sans-serif-type);
    color: black;
    font-weight: bold;
    font-size: 18px;
}

.eventEditCalloutInformation{
    font-family: var(--sans-serif-type);
    font-weight: bold;
    color: black;
    font-size: 16px;
    min-height: 27px;
    max-height: 27px;
    max-width: 190px;
    min-width: 190px;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.eventEditFinalPriceInformation{
    font-family: var(--sans-serif-type);
    font-weight: bold;
    color: black;
    font-size: 64px;
    min-height: 20px;
    margin-top: -15px;
}

.eventEditCalloutHR{
    background-color: black;
    border-color: black;
    height: 1px;
    margin: 15px 10px 15px 0px;
}

.eventEdit_SMSBody{
    font-family: var(--sans-serif-type);
    font-size: 14px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    background: lightblue;
    color: black;
}

.eventEdit_MMSBody{
    font-family: var(--sans-serif-type);
/*    font-size: 14px;*/
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-bottom: 10px;
/*    padding-right: -100px;*/
    background: #A1C9D6;
}

.MMS_ChatBubble{
    border-radius: 10px;
}

.eventEditApproverBounds{
    border-radius: 10px;
    border-color: gray;
    border-width: 1px;
    padding: 10px 10px 10px 10px;
    min-height: 165px;
    width: 480px;
}

.eventEditApprovers{
    border-width: 1px;
    border-radius: 10px;
    border-color: black;
    font-size: 12px;
    font-family: sans-serif;
    width: 460px;
    height: 30px;
    padding-top: 4px;
    text-align: center;
    margin-right: 30px;
    background-color: #70ff77;
}

.eventEditApproversTarget{
    border-width: 1px;
    border-radius: 10px;
    border-color: black;
    font-size: 12px;
    font-family: sans-serif;
    width: 565px;
    min-height: 280px;
    padding-top: 4px;
    text-align: center;
    margin-right: 10px;
    background-color: white;
    justify-content: center;
    align-items: center;
}

.areaCodeBar{
    background: dodgerblue;
    color: white;
    border-color: white;
    border-radius: 10px;
    border-width: 5px;
    height: 75px;
    cursor: pointer;
    min-width: 200px;
}

.areaCodeBar_NoAvailability{
    background: white;
    color: darkgray;
    border-color: darkgray;
    border-style: dotted;
    border-radius: 10px;
    border-width: 5px;
    height: 60px;
}

.areaCodeDivider{
    font-size: 64px;
    color: darkblue;
    margin-top: -18px;
    margin-left: -5px;
}

.areaCodeBar_AC{
    color: #e40e12;
    font-family: sans-serif;
    font-size: 32px;
    font-weight: bold;
    text-shadow: 1px 1px 2px whitesmoke;
}

.areaCodeBar_Qty{
    color: white;
    font-family: sans-serif;
    font-size: 48px;
    font-weight: bold;
    margin-left: -5px;
    margin-top: -3px;
    text-shadow: 1px 1px 2px #e40e12;
}

.areaCodeBar_AC_NoAvailability{
    color: gray;
    font-family: sans-serif;
    font-size: 24px;
    font-weight: bold;
}

.areaCodeDivider_NoAvailability{
    font-size: 48px;
    color: darkgray;
    margin-top: -13px;
    margin-left: -5px;
}

.areaCodeBar_Qty_NoAvailability{
    color: lightgray;
    font-family: sans-serif;
    font-size: 36px;
    font-weight: bold;
}

.areaCodeSelectorBack{
    background-color: white;
    border-color: black;
    border-width: 1px;
    border-radius: 10px;
    padding: 10px 10px 10px 10px;
    width: 400px;
    min-height: 500px;
}

.areaCodeSelection{
    color: white;
    background: dodgerblue;
    border-color: white;
    border-width: 2px;
    border-radius: 10px;
    height: 175px;
    width: 400px;
}

.areaCodeSelection_title{
    color: white;
    font-family: sans-serif;
    font-size: 14px;
    font-weight: normal;
    margin-left: 5px;
}

.areaCodeSelection_data{
    color: white;
    font-family: sans-serif;
    font-size: 64px;
    font-weight: bold;
    margin-top: 5px;
}

.areaCodeSelection_sub{
    color: white;
    font-family: sans-serif;
    font-size: 12px;
    font-weight: normal;
    margin-top: -20px;
}

.appliedApproverTargetRectangle{
    height: 230px;
    border-width: 2px;
    border-style: dashed;
    border-color: lightblue;
    fill: blue;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    margin: 0px 20px 0px 20px;
}

.appliedApprover{
    border-width: 1px;
    border-radius: 5px;
    border-color: black;
    font-size: 12px;
    font-family: sans-serif;
    min-height: 35px;
    margin-bottom: 4px;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
    background-color: white;
    justify-content: left;
    align-items: center;
}

.eventEditSampleNB{
    border-width: 1px;
    border-radius: 10px;
    border-color: black;
    font-size: 12px;
    font-family: sans-serif;
    width: 460px;
    height: 30px;
    padding-top: 4px;
    text-align: center;
    margin-right: 30px;
    background-color: #74dbff;
}

.eventEditSamplesTarget{
    border-width: 1px;
    border-radius: 10px;
    border-color: black;
    font-size: 12px;
    font-family: sans-serif;
    width: 835px;
    min-height: 280px;
    padding-top: 4px;
    text-align: center;
    margin-right: 10px;
    background-color: white;
    justify-content: center;
    align-items: center;
}

.appliedSampleTargetRectangle{
    height: 230px;
    border-width: 2px;
    border-style: dashed;
    border-color: lightblue;
    fill: blue;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    margin: 0px 20px 0px 20px;
}

.appliedSampleName{
    font-size: 9pt;
    font-family: sans-serif;
    margin-left: 10px;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.approverButtons{
    cursor: pointer;
}

.appliedSample{
    border-width: 1px;
    border-radius: 5px;
    border-color: black;
    font-size: 12px;
    font-family: sans-serif;
    min-height: 35px;
    margin-top: 4px;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
    background-color: white;
    justify-content: left;
    align-items: center;
    overflow-x: hidden;
    text-overflow: ellipsis;
    width: 550px;
}

.appliedApproverName{
    font-size: 9pt;
    font-family: sans-serif;
    margin-left: 10px;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.DataOverflowScroll{
    overflow: scroll;
}

.timePickerTZDetailsTile{
    border-width: 1px;
    border-radius: 10px;
    border-color: black;
    background-color: white;
    height: 150px;
    width: 550px;
    padding: 10px 10px 10px 20px;
}

.TZDetailsMainData{
    font-family: sans-serif;
    font-size: 48px;
    font-weight: bold;
}

.userEditTitle{
    font-family: sans-serif;
    font-size: 11px;
    font-weight: bold;
}

.eventEditApprovers{
    margin-bottom: 2px;
    font-family: sans-serif;
    font-size: 18px;
    font-weight: bold;
    height: 40px;
}

.eventEditSampleNB{
    margin-bottom: 2px;
    font-family: sans-serif;
    font-size: 18px;
    font-weight: bold;
    height: 40px;
}

.shadowBoxBackground{
    position: absolute;
    background-color: #eeeeee;
    opacity: 95%;
    width: 100vw;
    height: 100vw;
    z-index: 50;
    background-repeat: repeat;
    top: 0;
    left: 0;
}

.shadowBoxContent{
    position: absolute;
    margin-left: max(5px, calc((100vw - 1630px)/2));
    z-index: 50;
    background-repeat: repeat;
}

.searchText{
    width: 500px;
    margin-left: 20px;
    border-color: #00b8ff;
    border-width: 2px;
    border-radius: 5px;
    height: 45px;
    font-family: sans-serif;
    font-size: 24px;
    padding-left: 10px;
}

.reportPage{
    height: 1550px;
    width: 2050px;
}

.reportTitlePageTitle{
    font-weight: bold;
    font-size: 128px;
    color: white;
}

.reportTitlePageDate{
    font-weight: normal;
    margin-top: -10px;
    margin-bottom: 10px;
    font-size: 36px;
    color: white;
}

.reportTitlePageSubTitle{
    font-weight: bold;
    font-size: 32px;
    color: white;
}

.reportData{
    font-family: sans-serif;
    font-size: 128px;
    font-weight: bold;
}

.reportMsgOverview{
    position: relative;
    border-radius: 10px;
    background: white;
    height: 1000px;
    width: 1300px;
    margin-top: 100px;
    margin-left: 100px;
    padding-top: 100px;
    padding-left: 50px;
}

.reportEventResultsHeaderBody{
    font-family: var(--sans-serif-type);
    color: black;
    font-size: 16px;
    font-weight: bold;
}

.reportEventResultsHeaderDesc{
    font-family: var(--sans-serif-type);
    color: black;
    font-size: 10px;
}

.reportEventResultsHeaderTitle{
    font-family: var(--sans-serif-type);
    color: black;
    font-size: 18px;
}

.reportEventResultsHeaderEventTitle{
    font-family: var(--sans-serif-type);
    color: black;
    font-size: 36px;
    font-weight: bold;
}

.reportHR_Modal{
    border-color: gray;
    margin-bottom: 2px;
}

.reportEventResults_SMSBody{
    font-family: var(--sans-serif-type);
    font-size: 18px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
}

.modalCommitAck{
    border-color: black;
    border-width: 5px;
    border-radius: 10px;
    background: darkorange;
    position: 'absolute';
    margin-top: calc(50vh - 350px);
    margin-left: calc(50vw - 200px);
}

.modalCannotCommitAck{
    border-color: black;
    border-width: 5px;
    border-radius: 10px;
    background: red;
    position: 'absolute';
    margin-top: calc(50vh - 250px);
    margin-left: calc(50vw - 200px);
}

.modalCommitAck_Archive{
    border-color: black;
    border-width: 5px;
    border-radius: 10px;
    background: red;
    color: white;
    position: 'absolute';
    margin-top: calc(50vh - 200px);
    margin-left: calc(50vw - 200px);

}

.modalSystemMessageCommitAck{
    background: white;
    position: 'absolute';
    margin-top: calc(50vh - 200px);
    width: 100vw;
    height: 500px;
    padding: 10px 15px 10px 15px;
    text-align: center;
}

#range-slider {
    height: 60px;
    background: #333;
    overflow: hidden;
}

#range-slider .range-slider__thumb {
    width: 18px;
    height: 38px;
    border-radius: 4px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='%23333' viewBox='0 0 24 24'%3E%3Cpath d='M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z' /%3E%3C/svg%3E") #fff;
    background-repeat: no-repeat;
    background-position: center;
}

#range-slider .range-slider__range {
    border-radius: 6px;
    background: transparent;
    border: 4px solid #fff;
    box-sizing: border-box;
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, .75);
}

.dropFileRectangle_unify{
    display: flex;
    height: 150px;
    border-width: 2px;
    border-style: dashed;
    border-color: #1976d2;
    fill: blue;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
}

.dropFileTitle_unify{

    color: #1976d2;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 28px;
    cursor: pointer;
}

.unifyCallOut{
    width: 100%;
    height: 100px;
    border-radius: 10px;
    border-color: white;
    background: #1976d2;
    color: white;
}

.unifyComponentPage{
    overflow-y: scroll;
    height: 3000px;
    max-width: 1540px;
}

.passwordManagementBackground{
  /* The image used */
  background-image: url("https://afcuploadstorageprod.blob.core.windows.net/assets/1/profileManagement/sunrise_1.gif");

  /* Full height */
  height: 100vh;
  width: 100vw;

  max-height: 100vh;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.passwordManagementBackgroundBorder{
    background: white;
    width: 750px;
    height: 700px;
    border-radius: 10px;
    z-index: 99;
}

.homeBackground{
    /* The image used */
    background-image: url("https://afcuploadstorageprod.blob.core.windows.net/assets/1/home/WashMon_flagsBack.gif");

    /* Full height */
    height: 100vh;
    width: 100vw;

    max-height: 100vh;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.homePageMainDiv{
    width: 1600px;
    position: absolute;
    margin-left: max(5px, calc((100vw - 1600px)/2));
    background: transparent;
}

.homePageApplicationButton{
    margin-right: 20px;
    cursor: pointer;
}

.homePageApplicationIcon{
    height: 120px;
    margin: 10px;
}

.homePageComingSoonApplicationIcon{
    height: 80px;
    margin: 10px;
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 0px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 5px;
    border-radius: 6px;
    font-size: 12px;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;

    bottom: 100%;
    left: 50%;
    margin-left: -60px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}

.tooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.homePageLatest{
    height: calc(400px);
    margin-top: 10px;
    width: 100%;
    background: white;
}

.homePageTile{
    width: 395px;
    height: 370px;
    margin: 0px 10px 10px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 3px 10px gray;
    overflow-y: hidden;
}

.homePageDoubleWidthTile{
    width: 780px;
    height: 370px;
    margin: 0px 10px 10px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 3px 10px gray;
    overflow-y: hidden;
}

.homeComponentLoading{
    position: absolute;
    width: 780px;
    height: 300px;
    background: white;
    color: darkgray;
}

.menuDivider{
    height: 40px;
    width: 3px;
    margin-left: 5px;
    margin-right: 5px;
    background: black;
}

.homePacificTime{
    height: 75px;
    background: dodgerblue;
    border-radius: 5px;
    margin: 20px 10px 10px 10px;
}

.homeMountainTime{
    height: 75px;
    background: cornflowerblue;
    border-radius: 5px;
    margin: 0px 10px 10px 10px;
}

.homeCentralTime{
    height: 75px;
    background: deepskyblue;
    border-radius: 5px;
    margin: 0px 10px 10px 10px;
}

.homeEasternTime{
    height: 75px;
    background: lightskyblue;
    border-radius: 5px;
    margin: 0px 10px 10px 10px;
}

.dataMapperFileTile{
    width: 275px;
    height: 100px;
    margin: 10px 10px 10px 10px;
    background: white;
    border-radius: 10px;
    box-shadow: 2px 1px 1px lightgray;
    overflow-y: hidden;
    border-width: 2px;
    border-color: lightblue;
    cursor: pointer;
}

.dataMapperProcessedResultTile{
    width: 275px;
    height: 100px;
    margin: 10px 10px 10px 10px;
    background: white;
    border-radius: 10px;
    box-shadow: 2px 1px 1px lightgray;
    overflow-y: hidden;
    border-width: 6px;
    border-color: lightgreen;
    cursor: pointer;
}

.datamapperFileTileTitle{
    font-size: 14px;
    margin-left: 10px;
    margin-top: 10px;
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

}

.datamapperFileRemove{
    background: red;
    cursor: pointer;
    color: white;
    font-size: 14px;
    border-radius: 5px;
    border-width: 2px;
    border-color: darkred;
    margin-left: 240px;
    margin-top: -25px;
    width: 25px;
    height: 25px;
    font-weight: bold;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.datamapperFileMainConnector{
    border: 2px solid black;
    border-left: none;
    border-radius: 10px;
}

.datamaperFileConnector{
    border-top: 2px solid black;
}

.datamaperMosaicResultConnector{
    border-right: 6px solid lightgreen;
}

.datamaperFinalFilter{
    border-width: 10px;
    background: white;
    border-style: solid;
    border-color: lightgray;
    background-image: url('https://afcuploadstorageprod.blob.core.windows.net/assets/1/Amplify/controls.gif');
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    background-position: center;
}

.datamaperFinalProcessed{
    border-width: 10px;
    background: white;
    border-style: solid;
    border-color: lightgreen;
    background-image: url('https://afcuploadstorageprod.blob.core.windows.net/assets/1/Mosaic/mosaic-logo-full-white-back-padding.gif');
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    background-position: center;
}

.modalDatamapperColumnMapping{
    border-color: black;
    border-width: 5px;
    border-radius: 10px;
    background: white;
    position: 'absolute';
    margin-top: calc(50vh - 435px);
    margin-left: calc(50vw - 550px);
    width: 1100px;
    height: 870px;
}

.modalDatamapperRowFilter{
    border-color: black;
    border-width: 5px;
    border-radius: 10px;
    background: white;
    position: 'absolute';
    margin-top: calc(50vh - 350px);
    margin-left: calc(50vw - 550px);
    width: 1100px;
    height: 700px;
}

.modalDatamapperColumnMappingQty{
    text-align: right;
    justify-content: right;
    font-size: 48px;
    font-weight: bold;
    margin-top: -15;
    color: darkgray;
    width: calc(100% - 250px);
}

.datamapperFileTileQty{
    font-size: 32px;
    margin-left: 10px;
    margin-top: -5px;
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.datamapperFileTileQtyTitle{
    font-size: 9px;
    margin-left: 10px;
    margin-top: 10px;
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.datamapperPrelimQty{
    font-size: 14px;
    color: red;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 390px;
    width: 90px;
    margin-top: -63px;
    text-align: center;
    background: white;
    border-width: 2px;
    border-style: solid;
    border-color: lightblue;
    border-radius: 10px;
}

.datamapperFinalQty{
    font-size: 14px;
    color: red;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 600px;
    width: 90px;
    margin-top: -23px;
    text-align: center;
    background: white;
    border-width: 2px;
    border-style: solid;
    border-color: lightblue;
    border-radius: 10px;
}

.datamapperPrelimQtySingle{
    font-size: 14px;
    color: red;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 325px;
    width: 90px;
    margin-top: -64px;
    text-align: center;
    background: white;
    border-width: 2px;
    border-style: solid;
    border-color: lightblue;
    border-radius: 10px;
}

.datamapperFinalQtySingle{
    font-size: 14px;
    color: red;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 577px;
    width: 90px;
    margin-top: -23px;
    text-align: center;
    background: white;
    border-width: 2px;
    border-style: solid;
    border-color: lightblue;
    border-radius: 10px;
}

.datamapperFilterSettingsSingle{
    display: flex;
    width: 100px;
    height: 30px;
    margin-left: 448px;
    margin-top: 45px;
    justify-content: center;
}

.datamapperFilterSettings{
    display: flex;
    width: 100px;
    height: 30px;
    margin-left: 488px;
    margin-top: 45px;
    justify-content: center;
}

.datamapperFilterSettings_ShuffleActive{
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-right: 5px;
    background-position: center;
    background-image: url('https://afcuploadstorageprod.blob.core.windows.net/assets/1/Mosaic/Shuffle-Single-Icon_whiteFill.gif');
    background-size: contain;
    background-repeat: no-repeat;
}

.datamapperFilterSettings_FilterActive{
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-right: 5px;
    background-position: center;
    background-image: url('https://afcuploadstorageprod.blob.core.windows.net/assets/1/Mosaic/Filter-Single-Icon_whiteFill.gif');
    background-size: contain;
    background-repeat: no-repeat;
}

.datamapperRowFilterInfoTitles{
    font-size: 12px;
    font-weight: bold;
}

.datamapperRowFilterInfoData{
    font-size: 20px;
    margin-top: -25px;
}

.datamapperRowFilterResetRange{
    color: red;
    font-size: 12px;
    margin-top: 10px;
    text-align: right;
    margin-right: 45px;
    text-decoration: underline;
    cursor: pointer;
}

.datamapperDropFileRectangle{
    display: flex;
    height: 150px;
    border-width: 2px;
    border-style: dashed;
    border-color: lightblue;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
}

.datamapperDropFileTitle{

    color: lightblue;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 28px;
    cursor: pointer;
}

.datamapperDropFileRectangle_Max{
    display: flex;
    height: 150px;
    border-width: 2px;
    border-style: dashed;
    border-color: orangered;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
}

.datamapperDropFileTitle_Max{
    color: orangered;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 28px;
}

.datamapperMainDisplay{
    display: flex;
    height: 620px;
    border-width: 2px;
    border-style: solid;
    border-color: lightblue;
    background-image: url('https://afcuploadstorageprod.blob.core.windows.net/assets/1/Mosaic/Mosaic-Logo-Diffused-back_Levels3.gif');
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
    margin: 10px 15px 10px 15px;
    justify-content: center;
    align-items: center;
}

.EventMessage_DAndDErrorMessage{
    font-size: 48px;
    justify-content: center;
    text-align: center;
    align-items: center;
    height: 950px;
}

.dataMapperInitialStats{
    background: lightgrey;
    height: 142px;
    width: 620px;
    border-width: 2px;
    border-style: solid;
    border-radius: 10px;
    border-color: lightblue;
    padding: 10px 10px 10px 20px;
    margin: 10px 10px 20px 10px;
    background: royalblue;
}

.dataMapperInitialStatsTitle{
    font-size: 16px;
    font-weight: normal;
    color: white;
    width: 460px;
}

.dataMapperInitialStatsData{
    font-size: 18px;
    font-weight: bold;
    color: white;
    text-align: right;
    width: 120px;
}

.datamapperInitialStatsModal{
    border-color: black;
    border-width: 5px;
    border-radius: 10px;
    background: white;
    position: 'absolute';
    margin-top: calc(50vh - 350px);
    margin-left: calc(50vw - 325px);
    width: 650px;
    height: 700px;
}

.SMSPreview_MMSImage{
    max-height: 250px;
    max-width: 250px;
    border-radius: 10px;
}

.SMSPreview_MMSImage_Border{
    max-height: 320px;
    max-width: 300px;
    margin-left: 25px;
    border-width: 2px;
    border-style: solid;
    border-radius: 10px;
    border-color: lightblue;
}

.SMSPreview_modificationTile{
    width: 275px;
    height: 100px;
    margin: 10px 10px 10px 10px;
    background: red;
    border-radius: 10px;
    box-shadow: 2px 1px 1px lightgray;
    overflow-y: hidden;
    overflow-x: hidden;
    border-width: 2px;
    border-color: red;
    cursor: pointer;
}

.SMSPreview_modificationTile_InnerBoundary{
    width: 97.75%;
    height: 95%;
    margin: 3px;
    background: black;
    color: white;
    border-radius: 10px;
    overflow-y: hidden;
    overflow-x: hidden;
    border-width: 2px;
    border-color: white;
    cursor: pointer;
}

.SMSPreview_modalWindow{
    border-color: black;
    border-width: 5px;
    border-radius: 10px;
    background: white;
    position: 'absolute';
    margin-top: calc(50vh - 600px);
    margin-left: calc(50vw - 485px);
    width: 970px;
    height: 1200px;
}

.MMSAssets_selectionBoxes{
    display: grid;
    height: 300px;
    width: 300px;
    border-width: 2px;
    border-style: dashed;
    border-color: #A3A3A3;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
}

.MMSAssetSelection_Title{
    color: #A3A3A3;
    font-size: 24px;
}

.MMSFacet_modalWindow{
    border-color: black;
    border-width: 5px;
    border-radius: 10px;
    background: white;
    position: 'absolute';
    margin-top: calc(50vh - 485px);
    margin-left: calc(50vw - 600px);
    width: 1200px;
    height: 970px;
}

.LogoffImminent_modalWindow{
    border-color: #0099ff;
    border-width: 5px;
    border-radius: 10px;
    background: white;
    position: 'absolute';
    margin-top: calc(50vh - 250px);
    margin-left: calc(50vw - 250px);
    width: 500px;
    height: 500px;
}

.LogoffImminent_modalWindow_title{
    color: #0099ff;
    font-size: 24px;
    margin-left: 20px;
    margin-top: -25px;
    min-width: 495px;
}

.LogoffImminent_modalWindow_countdown{
    display: flex;
    color: #0099ff;
    font-size: 72px;
    margin-left: 0px;
    margin-top: 0px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
}

.LogoffImminent_modalWindow_text{
    display: flex;
    color: #0099ff;
    font-size: 14px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
}

@layer utilities {
    .ta-scrollbar::-webkit-scrollbar {
        width: 10px;
        height: 20px;
    }

    .ta-scrollbar::-webkit-scrollbar-track {
        border-radius: 100vh;
        background: #b3defd;
    }

    .ta-scrollbar::-webkit-scrollbar-thumb {
        background: #0d3958;
        border-radius: 100vh;
        border: 2px solid #a4d3f5;
    }

    .ta-scrollbar::-webkit-scrollbar-thumb:hover {
        background: #0d3958;
    }
}
