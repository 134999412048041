.accountHeader_Phone{
/*    width: calc(100vw - 50px);*/
    width: 100vw;
    height: 120px;
    background: black;
    background-image: url("https://afcuploadstorageprod.blob.core.windows.net/assets/1/header.jpeg");
    margin-top: 0px;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.accountHeaderImage_Phone{
    margin-top: -90px;
    margin-left: 10px;
    margin-bottom: 10px;
    height: 100px;
    width: 100px;
    z-index: 10;
}

.accountHeaderCompanyName_Phone{
    margin-top: -90px;
    margin-left: 120px;
}

.accountHeaderCompanyNameTitle_Phone{
    font-weight: bold;
    font-size: 36px;
    color: white;
}

.accountHeaderCompanyNameTitleReport_Phone{
    font-weight: bold;
    font-size: 28px;
    color: white;
}

.topBar_Phone{
    width: 100vw;
}

.hierarchy_nav_phone{
    /*margin-left: calc((2030/2) - (385/2))px;*/
    margin-left: 20px;
}

.hierarchy_label_phone{
    position: absolute;
    font-family: sans-serif;
    font-size: 8px;
    color: black;
}


.mainPageDiv_Phone{
    width: 100vw;
    position: absolute;
/*    margin-left: max(5px, calc((100vw - 790px)/2));*/
    background: white;
}

.createItemTitle_Phone{
    font-family: sans-serif;
    font-size: 32px;
    font-weight: bold;
}

.dashboardTileTitle_Phone{
    font-family: sans-serif;
    font-size: 14px;
    font-weight: bold;
    margin-top: -5px;
}


.dashboardTileMainData_Phone{
    font-family: sans-serif;
    font-size: 36px;
    font-weight: bold;
    margin-top: -10px;
}

.dashboardTileSubtext_Phone{
    font-family: sans-serif;
    font-size: 10px;
    margin-top: -7px;
}

.dashboardTile_Phone{
    width: 49vw;
    height: 185px;
    margin: 0px 5px 5px 0px;
    background: white;
    border-radius: 0px;
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.dashboardTileReport_Phone{
    width: 100vw;
    height: 185px;
    margin: 0px 5px 5px 0px;
    background: white;
    border-radius: 0px;
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.dashboardTileRightCol_Phone{
    width: 345px;
    height: 185px;
    margin: 0px 0px 10px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.dashboardHalfHeightTile_Phone{
    width: 100vw;
    height: 100px;
    margin: 0px 0px 5px 0px;
    background: white;
/*    border-radius: 10px;*/
    box-shadow: 5px 3px 10px lightgray;
    min-width: 330px;
    overflow-y: hidden;
}

.dashboardDoubleHeightTile_Phone{
    width: 100vw;
    height: 375px;
    margin: 0px 10px 10px 0px;
    background: white;
/*    border-radius: 10px;*/
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.dashboardDoubleWidthTile_Phone{
    width: 100vw;
    height: 185px;
    margin: 0px 10px 5px 0px;
    background: white;
    border-radius: 0px;
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.dashboardDoubleWidthDoubleHeightTile_Phone{
    width: 100vw;
    height: 375px;
    margin: 0px 0px 5px 0px;
    background: white;
/*    border-radius: 10px;*/
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.dashboardTripleWidthDoubleHeightTile_Phone{
    width: 1010px;
    height: 375px;
    margin: 0px 10px 10px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.dashboardTripleHeightTile_Phone{
    width: 100vw;
    height: 560px;
    margin: 0px 0px 5px 0px;
    background: white;
/*    border-radius: 10px;*/
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.dashboardTripleWidthTripleHeightTile_Phone{
    width: 1010px;
    height: 560px;
    margin: 0px 10px 10px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.dashboardQuadHeightTile_Phone{
    width: 100vw;
    height: 740px;
    margin: 0px 0px 5px 0px;
    background: white;
/*    border-radius: 10px;*/
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.dashboardFullWidthTile_Phone{
    width: 100vw;
    height: 185px;
    margin: 0px 0px 5px 0px;
    background: white;
/*    border-radius: 10px;*/
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.dashboardFullWidthHalfHeightTile_Phone{
    width: 100vw;
    height: 90px;
    margin: 0px 10px 10px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.dashboardFullWidthDoubleHeightTile_Phone{
    width: 100vw;
    height: 375px;
    margin: 0px 10px 10px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.dashboardFullWidthTripleHeightTile_Phone{
    width: 100vw;
    height: 565px;
    margin: 0px 10px 10px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.dashboardCampaignListFilters_Modal_Phone{
    overflow-y: scroll;
    max-height: 50vw;
    width: 216px;
    height: 500px;
    margin: 10px 0px 20px 0px;
/*    flex-wrap: wrap;*/
    background: white;
    border-radius: 10px;
    border-color: #346793;
    padding: 10px 10px 10px 10px;
    border-width: 5px;

    vertical-align: top;
/*    display: inline-block;*/
}

.dashboardCampaignListFiltersTitle_Modal_Phone{
    font-family: sans-serif;
    font-size: 24px;
    font-weight: bold;
}

.dashboardCampaignList_Modal_Phone{
    overflow-y: scroll;
    max-height: 50vw;
    width: 790px;
    height: 500px;
    margin: 10px 10px 20px 10px;
/*    flex-wrap: wrap;*/
    background: white;
    border-radius: 10px;
    border-color: #346793;
    padding: 10px 10px 10px 10px;
    border-width: 5px;

    vertical-align: top;
/*    display: inline-block;*/
}

.dashboardCampaignListContents_Modal_Phone{
/*    margin: 10px 10px 20px 20px;*/
    flex-wrap: wrap;
    background: white;
/*    max-height: calc(50vw - 20px);*/
    width: 770px;
}

.dashboardCampaign_Modal_Phone{
    font-family: sans-serif;
    width: 245px;
    height: 75px;
    background-color: white;
    color: black;
    border-width: 4px;
    border-color: #639aca;
    border-radius: 10px;
    border-spacing: 10px;
    cursor: pointer;
    padding: 10px 10px 10px 10px;
    box-shadow: 5px 5px 5px lightgray;
}

.dashboardCampaignHighlight_Modal_Phone{
    width: 15px;
    height: 93px;
    background-color: #031236;
    border-radius: 10px 0px 0px 10px;
    margin-right: 5px;
}

.dashboardCampaignTitle_Modal_Phone{
    font-weight: bold;
    font-size: 32px;
    font-family: sans-serif;
    max-width: 460px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.dashboardCampaignSubTitle_Modal_Phone{
    font-size: 10px;
    font-family: sans-serif;
    width: 50px;
}

.dashboardCampaignSubData_Modal_Phone{
    font-size: 14px;
    font-weight: bold;
    font-family: sans-serif;
    width: 50px;
}

.dashboardTileHR_Modal_Phone{
    border-color: gray;
    margin-bottom: 2px;
}

.dashboardCampaignStates_ModalList_Phone{
    flex-wrap: wrap;
    width: 150px;
    height: 300px;
    overflow-y: scroll;
}

.campaignListNavButtons_Phone{
    margin-left: 510px;
}

.dashboardCampaignList_Phone{
    overflow-y: scroll;
    max-height: 640px;
    margin: 10px 10px 20px 0px;
    vertical-align: top;
    display: inline-block;
}


.dashboardCampaign_Phone{
    width: calc(100vw - 5px);
    height: 95px;
    background-color: #293f78;
    color: white;
    border-width: 2px;
    border-color: white;
    border-radius: 10px;
    border-spacing: 10px;
    cursor: pointer;
}

.dashboardCampaignHighlight_Phone{
    width: 7px;
    height: 70px;
    background-color: #031236;
    border-radius: 10px 0px 0px 10px;
    margin-right: 5px;
}

.dashboardCampaignTitle_Phone{
    font-weight: bold;
    font-size: 18px;
    font-family: sans-serif;
    min-width: 175px;
    max-width: 175px;
    overflow-x: hidden;
    overflow-y: hidden;
    text-overflow: ellipsis;

}

.dashboardCampaignSubTitle_Phone{
    font-size: 10px;
    font-family: sans-serif;
    width: 50px;
}

.dashboardCampaignSubData_Phone{
    font-size: 14px;
    font-weight: bold;
    font-family: sans-serif;
    width: 50px;
}

.dashboardTileHR_Phone{
    border-color: gray;
    margin-bottom: 2px;
}

.dashboardCampaignPendingEvents_Phone{
    background: orangered;
    border-width: 2px;
    border-color: white;
    width: 50px;
    height: 35px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    font-family: sans-serif;
    margin-top: 8px;
    margin-left: 25px;
    border-radius: 10px;
    justify-content: center;
    display: flex;
}

.eventReportChartPhone{
    width: 100%;
    margin: 0px;

}

.eventReportCollapseDetails_Phone{
/*    border-width: 1px;*/
    width: 100vw;
    border-color: black;
    border-radius: 0px;
    background: white;
    box-shadow: 5px 3px 10px lightgray;
    margin: 0px 10px 10px 0px;
}

.eventList_Phone{
    margin-top: 0px;
    padding-top: 10px;
    width: 100vw;
    background: white;
}

.eventListTable_Phone{
    margin: 10px 10px 10px 10px;
}

.eventRespTable_Phone{
    margin: 10px 10px 10px 10px;
    width: 790px;
    max-height: 1000px;
    overflow-x: scroll;
}

.fullScreenLoading_Phone{
    width: 790px;
    height: 100vh;
    background: white;
    color: darkgray;
}

.componentLoading_Phone{
    position: absolute;
    width: 100vw;
    height: 360px;
    background: white;
    color: darkgray;
}

.eventResultsHeaderBody_Phone{
    font-family: var(--sans-serif-type);
    color: black;
    font-size: 16px;
    font-weight: bold;
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.eventResultsHeaderEventTitle_Phone{
    font-family: var(--sans-serif-type);
    color: black;
    font-size: 24px;
    font-weight: bold;
    width: 400px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.eventListComponent_Phone{
/*    width: calc(100vw - 10px);*/
    width: 450px;
}

.hierarchyNavigationTileDouble_Phone{
    width: 100vw;
    height: 100px;
    margin: 10px 10px 10px 0px;
    background: white;
/*    border-radius: 10px;*/
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.signinBody_Phone{
    position: fixed;
/*    height: calc(100vh - 300px);*/
    width: calc(50vw);
    margin: auto;
    margin-top: -600px;
    margin-left: 32px;
/*    margin-top: calc(-1 * 75%);*/
    width: 384px;
    z-index: 9;
}
