.accountHeader_Mobile{
/*    width: calc(100vw - 50px);*/
    width: 790px;
    height: 120px;
    background: black;
    background-image: url("https://afcuploadstorageprod.blob.core.windows.net/assets/1/header.jpeg");
    margin-top: 0px;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.accountHeaderImage_Mobile{
    margin-top: -90px;
    margin-left: 50px;
    margin-bottom: 10px;
    height: 100px;
    width: 100px;
    z-index: 10;
}

.accountHeaderCompanyName_Mobile{
    margin-top: -90px;
    margin-left: 170px;
}

.accountHeaderCompanyNameTitle_Mobile{
    font-weight: bold;
    font-size: 36px;
    color: white;
}

.topBarTablet{
    width: 100vw;
}

.mainPageDiv_Mobile{
    width: 790px;
    position: absolute;
    margin-left: max(5px, calc((100vw - 790px)/2));
    background: white;
    color: black;
}

.createItemTitle_Mobile{
    font-family: sans-serif;
    font-size: 32px;
    font-weight: bold;
}

.dashboardTileTitle_Mobile{
    font-family: sans-serif;
    font-size: 14px;
    font-weight: bold;
    margin-top: -5px;
}


.dashboardTileMainData_Mobile{
    font-family: sans-serif;
    font-size: 42px;
    font-weight: bold;
    margin-top: -10px;
}

.dashboardTileSubtext_Mobile{
    font-family: sans-serif;
    font-size: 10px;
    margin-top: -7px;
}

.dashboardTile_Mobile{
    width: 330px;
    height: 185px;
    margin: 0px 10px 10px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.dashboardTileRightCol_Mobile{
    width: 345px;
    height: 185px;
    margin: 0px 0px 10px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.dashboardHalfHeightTile_Mobile{
    width: 330px;
    height: 90px;
    margin: 0px 10px 10px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 3px 10px lightgray;
    min-width: 330px;
    overflow-y: hidden;
}

.dashboardDoubleHeightTile_Mobile{
    width: 330px;
    height: 375px;
    margin: 0px 10px 10px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.dashboardDoubleWidthTile_Mobile{
    width: 665px;
    height: 185px;
    margin: 0px 10px 10px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.dashboardDoubleWidthDoubleHeightTile_Mobile{
    width: 670px;
    height: 375px;
    margin: 0px 10px 10px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.dashboardTripleWidthDoubleHeightTile_Mobile{
    width: 1010px;
    height: 375px;
    margin: 0px 10px 10px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.dashboardTripleHeightTile_Mobile{
    width: 330px;
    height: 560px;
    margin: 0px 10px 10px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.dashboardTripleWidthTripleHeightTile_Mobile{
    width: 1010px;
    height: 560px;
    margin: 0px 10px 10px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.dashboardFullWidthTile_Mobile{
    width: 100vw;
    height: 185px;
    margin: 0px 10px 10px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.dashboardFullWidthHalfHeightTile_Mobile{
    width: 100vw;
    height: 90px;
    margin: 0px 10px 10px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.dashboardFullWidthDoubleHeightTile_Mobile{
    width: 100vw;
    height: 375px;
    margin: 0px 10px 10px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.dashboardFullWidthTripleHeightTile_Mobile{
    width: 100vw;
    height: 565px;
    margin: 0px 10px 10px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 3px 10px lightgray;
    overflow-y: hidden;
}

.dashboardCampaignListFilters_Modal_Mobile{
    overflow-y: scroll;
    max-height: 50vw;
    width: 216px;
    height: 500px;
    margin: 10px 0px 20px 0px;
/*    flex-wrap: wrap;*/
    background: white;
    border-radius: 10px;
    border-color: #346793;
    padding: 10px 10px 10px 10px;
    border-width: 5px;

    vertical-align: top;
/*    display: inline-block;*/
}

.dashboardCampaignListFiltersTitle_Modal_Mobile{
    font-family: sans-serif;
    font-size: 24px;
    font-weight: bold;
}

.dashboardCampaignList_Modal_Mobile{
    overflow-y: scroll;
    max-height: 50vw;
    width: 790px;
    height: 500px;
    margin: 10px 10px 20px 10px;
/*    flex-wrap: wrap;*/
    background: white;
    border-radius: 10px;
    border-color: #346793;
    padding: 10px 10px 10px 10px;
    border-width: 5px;

    vertical-align: top;
/*    display: inline-block;*/
}

.dashboardCampaignListContents_Modal_Mobile{
/*    margin: 10px 10px 20px 20px;*/
    flex-wrap: wrap;
    background: white;
/*    max-height: calc(50vw - 20px);*/
    width: 770px;
}

.dashboardCampaign_Modal_Mobile{
    font-family: sans-serif;
    width: 245px;
    height: 75px;
    background-color: white;
    color: black;
    border-width: 4px;
    border-color: #639aca;
    border-radius: 10px;
    border-spacing: 10px;
    cursor: pointer;
    padding: 10px 10px 10px 10px;
    box-shadow: 5px 5px 5px lightgray;
}

.dashboardCampaignHighlight_Modal_Mobile{
    width: 15px;
    height: 93px;
    background-color: #031236;
    border-radius: 10px 0px 0px 10px;
    margin-right: 5px;
}

.dashboardCampaignTitle_Modal_Mobile{
    font-weight: bold;
    font-size: 32px;
    font-family: sans-serif;
    max-width: 460px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.dashboardCampaignSubTitle_Modal_Mobile{
    font-size: 10px;
    font-family: sans-serif;
    width: 50px;
}

.dashboardCampaignSubData_Modal_Mobile{
    font-size: 14px;
    font-weight: bold;
    font-family: sans-serif;
    width: 50px;
}

.dashboardTileHR_Modal_Mobile{
    border-color: gray;
    margin-bottom: 2px;
}

.dashboardCampaignStates_ModalList_Mobile{
    flex-wrap: wrap;
    width: 150px;
    height: 300px;
    overflow-y: scroll;
}

.campaignListNavButtons_Mobile{
    margin-left: 510px;
}

.dashboardCampaignList_Mobile{
    overflow-y: scroll;
    max-height: 640px;
    margin: 10px 10px 20px 10px;
    vertical-align: top;
    display: inline-block;
}


.dashboardCampaign_Mobile{
    width: 300px;
    height: 75px;
    background-color: #293f78;
    color: white;
    border-width: 2px;
    border-color: white;
    border-radius: 10px;
    border-spacing: 10px;
    cursor: pointer;
}

.dashboardCampaignHighlight_Mobile{
    width: 7px;
    height: 70px;
    background-color: #031236;
    border-radius: 10px 0px 0px 10px;
    margin-right: 5px;
}

.dashboardCampaignTitle_Mobile{
    font-weight: bold;
    font-size: 18px;
    font-family: sans-serif;
    min-width: 175px;
    max-width: 175px;
    overflow-x: hidden;
    overflow-y: hidden;
    text-overflow: ellipsis;

}

.dashboardCampaignSubTitle_Mobile{
    font-size: 10px;
    font-family: sans-serif;
    width: 50px;
}

.dashboardCampaignSubData_Mobile{
    font-size: 14px;
    font-weight: bold;
    font-family: sans-serif;
    width: 50px;
}

.dashboardTileHR_Mobile{
    border-color: gray;
    margin-bottom: 2px;
}

.dashboardCampaignPendingEvents_Mobile{
    background: orangered;
    border-width: 2px;
    border-color: white;
    width: 50px;
    height: 35px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    font-family: sans-serif;
    margin-top: 8px;
    margin-left: 25px;
    border-radius: 10px;
    justify-content: center;
    display: flex;
}

.eventReportChartMobile{
    width: 100%;
    margin: 0px;

}

.eventReportCollapseDetails_Mobile{
/*    border-width: 1px;*/
    width: 790px;
    border-color: black;
    border-radius: 10px;
    background: white;
    box-shadow: 5px 3px 10px lightgray;
    margin: 0px 10px 10px 0px;
}

.eventListMobile{
    margin-top: 30px;
    padding-top: 10px;
    width: 790px;
    background: white;
}

.eventRespTable_Mobile{
    margin: 10px 10px 10px 10px;
    width: 790px;
    max-height: 1000px;
    overflow-x: scroll;
}

.fullScreenLoading_Mobile{
    width: 790px;
    height: 100vh;
    background: white;
    color: darkgray;
}

.homeBackground_Tablet{
  /* The image used */
  background-image: url("https://afcuploadstorageprod.blob.core.windows.net/assets/1/home/WashMon_flagsBack.gif");

  /* Full height */
  height: 110vh;
  width: 100vw;

  max-height: 110vh;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.homeBackground_Phone{
  /* The image used */
  background-image: url("https://afcuploadstorageprod.blob.core.windows.net/assets/1/home/WashMon_flagsBack.gif");

  /* Full height */
  height: 135vh;
  width: 100vw;

  max-height: 135vh;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.homePageTile_Phone{
    width: 50vw;
    height: 370px;
    margin: 10px 0px 0px 0px;
    background: white;
/*    border-radius: 10px;*/
/*    box-shadow: 5px 3px 10px gray;*/
    overflow-y: scroll;
}

.homePageFullWidthTile_Phone{
    width: 100vw;
    height: 370px;
    margin: 10px 0px 0px 0px;
    background: white;
/*    border-radius: 10px;*/
/*    box-shadow: 5px 3px 10px gray;*/
    overflow-y: scroll;
}

.homeComponentLoading_Phone{
    position: absolute;
    width: 780px;
    height: 317px;
    background: white;
    color: darkgray;
}

.passwordManagementBackgroundBorder_Phone{
    background: white;
    width: 100%;
    height: 700px;
/*    margin-left: calc(50vw/2);*/
/*    border-radius: 10px;*/
    z-index: 99;
}
